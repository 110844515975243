<template>
    <div class="module-salary-statement" id="modal-set" v-if="isPageLoaded">
        <div class="modal-dialog module-set__inner" role="document">
            <div class="modal-content">
                <div class="module-set__header">
                    <h3 class="block-title module-set__title">Настройка модуля:</h3>
                    <h3 class="block-title module-set__title">"Курсы"</h3>
                    <button type="button" class="modal__close-btn" aria-label="Close" @click="$modal.hide($options.name)">x
                    </button>
                </div>
                <div class="module-set__body">
                    <div class="module-set__subtitle">Сотрудники, которые могут администрировать раздел курсы</div>
                    <ul class="module-set__list">
                        <li class="module-set__item" v-for="(access, index) in form.users" :key="access.uuid">
                            <v-select2 class="v-select2" style="width: 100%;"
                                       label="full_name"
                                       v-model="access.user_id"
                                       :reduce="user => user.id"
                                       :options="access.users"
                                       :clearable="false" :searchable="true">
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>
                            <button class="module-set__del-btn" type="button" @click="removeAccess(index)">
                                <svg width="9" height="9" viewBox="0 0 9 9"><g><g><path fill="#b8b8b8" d="M.694 9.006a.707.707 0 0 1-.503-.21.719.719 0 0 1 0-1.012l3.272-3.29L.19 1.204a.719.719 0 0 1 0-1.013.709.709 0 0 1 1.007 0l3.271 3.29L7.741.191a.709.709 0 0 1 1.007 0 .719.719 0 0 1 0 1.012l-3.272 3.29 3.272 3.291a.719.719 0 0 1 0 1.012.707.707 0 0 1-1.007 0L4.47 5.506l-3.271 3.29a.707.707 0 0 1-.504.21"></path></g></g></svg>
                            </button>
                        </li>
                    </ul>
                    <button class="module-set__add-btn" @click="addUser">Добавить</button>
                </div>
                <div class="module-set__footer">
                    <button class="module-set__cansel-btn v-btn v-btn--transparent" @click="$modal.hide('courses')">Отмена</button>
                    <button type="button" class="module-set__save-btn v-btn" @click="onSubmit">
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import session from '@/api/session';

export default {
    name: "CourseSetting",
    data() {
        return {
            form: {
                users: []
            },
            users: [],
            isPageLoaded: false
        }
    },
    methods: {
        addUser() {
            this.form.users.push({
                uuid: this.$uuid.v1(),
                user_id: null,
                users: this.users
            })
        },
        removeAccess(index) {
            this.form.users.splice(index, 1);
        },
        async onSubmit() {
            try {
                let data = {...this.form};
                data.users = data.users.map(item => item.user_id);
                const request = await session.post(`/api/v1/sett_course/save/`, data);
                this.$modal.hide('courses');
                this.$swal({
                    title: 'Отлично!',
                    text: 'Данные изменены',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1400,
                });
            } catch(error) {
                console.error(error)
            }
        },
    },
    async created() {
        await session
            .get(`/api/v1/users/all_without_admin/`)
            .then(response => {
                let data = response.data;
                this.users = data;
            });
        const request = await session.get(`/api/v1/sett_course/instance/`);
        const {data} = request;
        this.form = {
            users: data.users.map(item => ({
                user_id: item.id,
                full_name: item.full_name,
                users: this.users
            }))
        };
        this.isPageLoaded = true;
    },
}
</script>

<style lang="scss">
.v--modal-overlay[data-modal="courses"] {
    .v--modal-box {
        overflow: visible !important;
    }
}
</style>
